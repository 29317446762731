import React from "react";

import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade'

import img from '../../img/Home/img.png'

import { Link } from "react-router-dom"

function UseTrade () {

    const { t } = useTranslation();
 
    return(
        <div className="mt-[80px] xl:mt-[200px] overflow-hidden relative">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between">
                <div className="xl:max-w-[525px]">
                    <p className="roboto text-[#2F5D52] font-[700] text-[25px] xl:text-[40px]">{t('usetrade.1')}</p>
                    <p className="mt-[20px] xl:mt-[30px] open-sans text-[#232323] text-[14px] xl:text-[16px]">{t('usetrade.2')}</p>
                    <p className="mt-[15px] open-sans text-[#232323] text-[14px] xl:text-[16px]">{t('usetrade.3')}</p>
                    <p className="mt-[15px] open-sans text-[#232323] text-[14px] xl:text-[16px]">{t('usetrade.4')}</p>
                    <Link to="https://user.millennium-trading.site/signup"><div className="cursor-pointer button2 flex mt-[20px] xl:mt-[40px]">
                        <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></Link>
                </div>
                <Fade right>
                    <div className="xl:absolute right-0 ml-auto mx-[-20px] xl:mx-0 mt-[50px] xl:mt-0">
                        <img src={img} alt="" />
                    </div>
                </Fade>
            </div>
        </div>
    );
}

export default UseTrade;