import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom"

const productsData = [
    { 
        index: 0,
        title: 'NANO',
        text1: '250 USD',
        text2: 'TRADE Platform',
        text3: '0.00003',
        text4: '1:400',
        text5: '6 USD/lot',
        text6: '0.04 USD per CFD (min 6 USD)',
        text7: 'EUR, USD',
        text8: '✔',
        text9: '✔',
        text10: '0.01',
        text11: '100 000',
        text12: 'Unlimited',
        text13: '✔',
    },
    { 
        index: 1,
        title: 'STANDARD',
        text1: '500 USD',
        text2: 'TRADE Platform',
        text3: '0.00013',
        text4: '1:400',
        text5: '✖',
        text6: '0.04 USD per CFD (min 6 USD)',
        text7: 'EUR, USD',
        text8: '✔',
        text9: '✔',
        text10: '0.01',
        text11: '100 000',
        text12: 'Unlimited',
        text13: '✔',
    },
    { 
        index: 2,
        title: 'MAX',
        text1: '5.000 USD',
        text2: 'TRADE Platform',
        text3: '0.0002',
        text4: '1:400',
        text5: '✖',
        text6: '0.06 USD per CFD (min 8 USD)',
        text7: 'EUR, USD',
        text8: '✔',
        text9: '✔',
        text10: '0.01',
        text11: '100 000',
        text12: 'Unlimited',
        text13: '✔',
    },
    { 
        index: 3,
        title: 'TERA',
        text1: '10.000 USD',
        text2: 'TRADE Platform',
        text3: '0.00003',
        text4: '1:400',
        text5: '6 USD/lot',
        text6: '0.04 USD per CFD (min 6 USD)',
        text7: 'EUR, USD',
        text8: '✔',
        text9: '✔',
        text10: '0.01',
        text11: '100 000',
        text12: 'Unlimited',
        text13: '✔',
    },
    { 
        index: 4,
        title: 'ALPHA',
        text1: '25.000 USD',
        text2: 'TRADE Platform',
        text3: '0.00001',
        text4: '1:400',
        text5: 'from 1.5 USD/lot',
        text6: '✖',
        text7: 'EUR, USD',
        text8: '✔',
        text9: '✔',
        text10: '0.01',
        text11: '130.000',
        text12: 'Unlimited',
        text13: '✔',
    },
]

function ComparePick () {

    const { t } = useTranslation();

    const [activeIndex, setActiveIndex] = useState(0);

    const handleButtonClick = (index) => {
        setActiveIndex(index);
    };
 
    return(
        <div className="pt-[80px] xl:pt-[200px] relative">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="flex flex-col xl:flex-row justify-between xl:h-[542px] rounded-[20px] bg-[#EFECE7] relative">
                    <div>
                        <p className="mx-[20px] xl:mx-[50px] mt-[25px] max-w-[316px] text-[20px] xl:text-[35px] open-sans font-[700] text-[#2F5D52]">{t('accounttypes.4')}</p>
                        <p className="mx-[20px] xl:mx-[50px] max-w-[368px] text-[14px] xl:text-[16px] open-sans text-[#232323] mt-[30px]">{t('accounttypes.5')}</p>
                        <p className="mx-[20px] xl:mx-[50px] max-w-[368px] text-[12px] open-sans text-[#232323] mt-[15px]">{t('accounttypes.6')}</p>
                        <Link to="https://user.millennium-trading.site/signup"><div className="!bg-[#2F5D52] mx-[20px] xl:mx-[50px] cursor-pointer button2 flex mt-[40px] xl:mt-[50px]">
                            <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div></Link>
                    </div>
                    <div className="xl:absolute flex flex-col xl:flex-row right-[70px] top-[50px] mt-[50px] xl:mt-0">
                        <div className="flex xl:flex-col gap-[8px]">
                            {productsData.map((product) => (
                                <div onClick={() => handleButtonClick(product.index)} key={product.index} className={`${activeIndex === product.index ? 'bg-[#FB6640]' : 'bg-[#F2CABD]'} w-[107px] h-[55px] rounded-t-[20px] xl:rounded-l-[20px] cursor-pointer transition-all flex justify-center items-center`}>
                                    <p className={`open-sans font-[600] text-[14px] ${activeIndex === product.index ? 'text-[#FFFFFF]' : 'text-[#2A2B2B]'}`}>{product.title}</p>
                                </div>
                            ))}
                        </div>
                        <div className="xl:w-[520px] xl:h-[672px] boxcompare">
                            <div className="grid grid-cols-2 ml-[50px] mt-[30px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B] font-[600]">{t('accounttypes.7')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B] font-[600]">{t('accounttypes.8')}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.9')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text1}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.10')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text2}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.11')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text3}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.12')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text4}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.13')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text5}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.14')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text6}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.15')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text7}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.16')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text8}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.17')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text9}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.18')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text10}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.19')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text11}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.20')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text12}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                            <div className="grid grid-cols-2 ml-[50px]">
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{t('accounttypes.21')}</p>
                                <p className="open-sans text-[14px] text-[#2A2B2B]">{productsData[activeIndex].text13}</p>
                            </div>
                            <div className="bg-[#AAAAAA] h-[1px] mx-[50px] my-[9px]"></div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    );
}

export default ComparePick;