import React from "react";

import iphone from '../../img/Promotions/15iphone.png'

import Fade from "react-reveal/Fade";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom"

function WhyShould () {

    const { t } = useTranslation();
 
    return(
        <div className="pt-[80px] xl:pt-[200px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] relative overflow-hidden flex flex-col">
                <p className="text-[20px] xl:text-[35px] text-[#2F5D52] open-sans font-[700] mx-auto">{t('promotions.9')}</p>
                <div className="flex flex-col xl:flex-row justify-between mt-[128px]">
                    <div className="flex flex-col relative parent">
                        <div className="hidden xl:block slide-up absolute xl:w-[500px] h-[58px] rounded-t-[50px] bg-[#2F5D52] cursor-pointer">
                            <p className="text-white open-sans text-[16px] font-[700] text-center mt-[16px]">Get bonus</p>
                        </div>
                        <div className="blockwhy xl:w-[500px] h-[303px] rounded-b-[50px] rounded-t-[50px] xl:rounded-t-[0px] z-50 bg-[#EFECE7] flex flex-col items-center justify-center">
                            <p className="text-[#232323] xl:text-[30px] text-[20px]">{t('promotions.11')}</p>
                            <p className="max-w-[201px] text-[#232323] text-center mt-[40px] open-sans text-[14px] xl:text-[16px]">{t('promotions.12')}</p>
                            <Link to="https://user.millennium-trading.site/signup"><div className="cursor-pointer button2 flex mt-[40px]">
                                <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div></Link>
                        </div>
                    </div>
                    <div className="flex flex-col relative parent mt-[40px] xl:mt-0">
                        <div className="hidden xl:block slide-up absolute xl:w-[500px] h-[58px] rounded-t-[50px] bg-[#2F5D52] cursor-pointer">
                            <p className="text-white open-sans text-[16px] font-[700] text-center mt-[16px]">Get bonus</p>
                        </div>
                        <div className="blockwhy xl:w-[500px] h-[303px] rounded-b-[50px] rounded-t-[50px] xl:rounded-t-[0px] z-50 bg-[#EFECE7] flex flex-col items-center justify-center">
                            <p className="text-[#232323] xl:text-[30px] text-[20px]">{t('promotions.13')}</p>
                            <p className="max-w-[250px] text-[#232323] text-center mt-[40px] open-sans text-[14px] xl:text-[16px]">{t('promotions.14')}</p>
                            <Link to="https://user.millennium-trading.site/signup"><div className="cursor-pointer button2 flex mt-[40px]">
                                <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div></Link>
                        </div>
                    </div>
                </div> 
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto pt-[80px] xl:pt-[200px] relative">
                <div className="xl:h-[432px] rounded-[50px] bg-[#2F5D52] flex flex-col">
                    <p className="text-white font-[700] text-[25px] xl:text-[40px] open-sans mx-[25px] mt-[40px] xl:mx-[70px]">202%</p>
                    <p className="text-white font-[700] text-[52px] xl:text-[80px] open-sans mx-[25px] xl:mx-[70px]">{t('promotions.15')}</p>
                    <p className="text-white max-w-[525px] text-[14px] xl:text-[16px] open-sans mx-[25px] xl:mx-[70px]">{t('promotions.16')}</p>
                    <Link to="https://user.millennium-trading.site/signup"><div className="cursor-pointer button2 flex mt-[30px] ml-[25px] xl:ml-[70px] mx-auto">
                        <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></Link>
                    <div className="xl:absolute bottom-0 right-[30px] mx-[40px] xl:mx-0 mt-[30px] xl:mt-0">
                    <Fade>
                        <img src={iphone} alt="" />
                    </Fade>
                </div>
                </div>
                
            </div>
        </div>
    );
}

export default WhyShould;