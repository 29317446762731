import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade'

import switcher1 from '../../img/Markets/switcher1.png'
import switcher2 from '../../img/Markets/switcher2.png'
import switcher3 from '../../img/Markets/switcher3.png'

import { Link } from "react-router-dom"

function Forex () {

    const { t } = useTranslation();

    const [switcher, setSwicther] = useState(0);
 
    return(
        <>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[60px] xl:mt-[100px]">
                {switcher === 0 ? (
                <div className="flex flex-col xl:flex-row justify-between">
                    <Fade>
                        <div className="hidden xl:block w-[525px] h-[450px] rounded-[15px] overflow-hidden">
                            <img src={switcher1} alt="" />
                        </div>
                    </Fade>
                    <div className="mt-[50px] max-w-[525px]">
                        <div className="flex xl:w-[424px] h-[56px] rounded-[15px] bg-[#EFECE7] items-center justify-between">
                            <Fade><div onClick={() => setSwicther(0)} className="cursor-pointer ml-[5px] w-[91px] h-[48px] bg-[#2F5D52] rounded-[15px] flex items-center justify-center">
                                <p className="text-white roboto text-[14px] xl:text-[16px]">{t('market.5')}</p>
                            </div></Fade>
                            <Fade><div onClick={() => setSwicther(1)} className="cursor-pointer mr-[5px] w-[172px] h-[48px] bg-transparent rounded-[15px] flex items-center justify-center">
                                <p className="text-[#424242] roboto text-[14px] xl:text-[16px]">{t('market.6')}</p>
                            </div></Fade>
                            <Fade><div onClick={() => setSwicther(2)} className="cursor-pointer mr-[5px] w-[91px] h-[48px] bg-transparent rounded-[15px] flex items-center justify-center">
                                <p className="text-[#424242] roboto text-[14px] xl:text-[16px]">{t('market.7')}</p>
                            </div></Fade>
                        </div>
                        <p className="text-[#2F5D52] roboto text-[20px] xl:text-[30px] font-[700] mt-[30px] xl:mt-[50px]">{t('market.5')}</p>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#232323] mt-[15px] xl:mt-[30px]">{t('market.8')}</p>
                        <Link to="https://user.millennium-trading.site/signup"><div className="cursor-pointer button2 flex mt-[40px] xl:mt-[50px]">
                            <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div></Link>
                    </div>
                </div>
                ) : (<></>)}
                {switcher === 1 ? (
                <div className="flex flex-col xl:flex-row justify-between">
                    <Fade>
                        <div className="hidden xl:block w-[525px] h-[450px] rounded-[15px] overflow-hidden">
                            <img src={switcher2} alt="" />
                        </div>
                    </Fade>
                    <div className="mt-[50px] max-w-[525px]">
                        <div className="flex xl:w-[424px] h-[56px] rounded-[15px] bg-[#EFECE7] items-center justify-between">
                            <Fade><div onClick={() => setSwicther(0)} className="cursor-pointer ml-[5px] w-[91px] h-[48px] bg-transparent rounded-[15px] flex items-center justify-center">
                                <p className="text-[#424242] roboto text-[14px] xl:text-[16px]">{t('market.5')}</p>
                            </div></Fade>
                            <Fade><div onClick={() => setSwicther(1)} className="cursor-pointer mr-[5px] w-[172px] h-[48px] bg-[#2F5D52] rounded-[15px] flex items-center justify-center">
                                <p className="text-white roboto text-[14px] xl:text-[16px]">{t('market.6')}</p>
                            </div></Fade>
                            <Fade><div onClick={() => setSwicther(2)} className="cursor-pointer mr-[5px] w-[91px] h-[48px] bg-transparent rounded-[15px] flex items-center justify-center">
                                <p className="text-[#424242] roboto text-[14px] xl:text-[16px]">{t('market.7')}</p>
                            </div></Fade>
                        </div>
                        <p className="text-[#2F5D52] roboto text-[20px] xl:text-[30px] font-[700] mt-[30px] xl:mt-[50px]">{t('market.6')}</p>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#232323] mt-[15px] xl:mt-[30px]">{t('market.9')}</p>
                        <Link to="https://user.millennium-trading.site/signup"><div className="cursor-pointer button2 flex mt-[40px] xl:mt-[50px]">
                            <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div></Link>
                    </div>
                </div>
                ) : (<></>)}
                {switcher === 2 ? (
                <div className="flex flex-col xl:flex-row justify-between">
                    <Fade>
                        <div className="hidden xl:block w-[525px] h-[450px] rounded-[15px] overflow-hidden">
                            <img src={switcher3} alt="" />
                        </div>
                    </Fade>
                    <div className="mt-[50px] max-w-[525px]">
                        <div className="flex xl:w-[424px] h-[56px] rounded-[15px] bg-[#EFECE7] items-center justify-between">
                            <Fade><div onClick={() => setSwicther(0)} className="cursor-pointer ml-[5px] w-[91px] h-[48px] bg-transparent rounded-[15px] flex items-center justify-center">
                                <p className="text-[#424242] roboto text-[14px] xl:text-[16px]">{t('market.5')}</p>
                            </div></Fade>
                            <Fade><div onClick={() => setSwicther(1)} className="cursor-pointer mr-[5px] w-[172px] h-[48px] bg-transparent rounded-[15px] flex items-center justify-center">
                                <p className="text-[#424242] roboto text-[14px] xl:text-[16px]">{t('market.6')}</p>
                            </div></Fade>
                            <Fade><div onClick={() => setSwicther(2)} className="cursor-pointer mr-[5px] w-[91px] h-[48px] bg-[#2F5D52] rounded-[15px] flex items-center justify-center">
                                <p className="text-white roboto text-[14px] xl:text-[16px]">{t('market.7')}</p>
                            </div></Fade>
                        </div>
                        <p className="text-[#2F5D52] roboto text-[20px] xl:text-[30px] font-[700] mt-[30px] xl:mt-[50px]">{t('market.7')}</p>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#232323] mt-[15px] xl:mt-[30px]">{t('market.10')}</p>
                        <Link to="https://user.millennium-trading.site/signup"><div className="cursor-pointer button2 flex mt-[40px] xl:mt-[50px]">
                            <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div></Link>
                    </div>
                </div>
                ) : (<></>)}
            </div>
        </>
    );
}

export default Forex;