import React from "react";

import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade'

import stocks from '../../img/Markets/stocks.png'

import { Link } from "react-router-dom"

function TradeStocks () {

    const { t } = useTranslation();
 
    return(
        <>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[200px] relative overflow-hidden">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div>
                        <p className="open-sans text-[#2F5D52] text-[20px] xl:text-[35px] font-[700] max-w-[300px]">{t('market.11')}</p>
                        <p className="text-[14px] xl:text-[16px] open-sans text-[#232323] italic mt-[20px] xl:mt-[30px]">{t('market.12')}</p>
                        <p className="text-[14px] xl:text-[16px] roboto text-[#232323] mt-[15px]">{t('market.13')}</p>
                        <p className="text-[14px] xl:text-[16px] roboto text-[#232323] mt-[15px] max-w-[525px]">{t('market.14')}</p>
                        <Link to="https://user.millennium-trading.site/signup"><div className="cursor-pointer button2 flex mt-[40px] xl:mt-[50px]">
                            <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div></Link>
                    </div>
                    <div className="mt-[20px] xl:mt-0">
                        <Fade right>
                            <img src={stocks} alt="" />
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TradeStocks;