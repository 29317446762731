import React, { useState } from "react";

import { Trans, useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import block from '../../img/CFD/block.png'

import { Link } from "react-router-dom"

function WhyShould () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});

    const [currentIndex, setCurrentIndex] = useState(0);

    const updateIndex = (swiperInstance) => {
        setCurrentIndex(swiperInstance.realIndex);
    };
 
    return(
        <div className="pt-[80px] xl:pt-[200px] relative overflow-hidden pb-[80px] xl:pb-[200px]">
            <div className="max-w-[1280px] gap-[50px] relative xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between">
               <div className="relative hidden xl:block">
                    <img src={block} alt="" />
                    <div className="absolute inset-0 top-[60px] left-[50px]">
                        <p className="text-white roboto text-[20px] xl:text-[40px]"><Trans>{t('cfd.10')}</Trans></p>
                        <Link to="https://user.millennium-trading.site/signup"><div className="cursor-pointer button2 !bg-white flex mt-[30px] xl:mt-[40px]">
                            <p className="text-[#232323] open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19" stroke="#232323" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 5L19 12L12 19" stroke="#232323" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div></Link>
                    </div> 
               </div>
               <div className="xl:w-[730px] h-[382px] rounded-[30px] bg-[#EFECE7]">
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={1}
                        loop={true}
                        onInit={(e) => {
                            setSwiper(e);
                            setCurrentIndex(e.realIndex);
                        }}
                        onSlideChange={(e) => updateIndex(e)}
                        className=""
                    >
                        <SwiperSlide>
                            <div className="xl:mt-[60px] xl:ml-[60px] m-[40px] xl:w-[398px]">
                                <p className="text-[#232323] open-sans font-[700]">{t('cfd.11')}</p>
                                <p className="mt-[30px] open-sans text-[#232323] text-[16px]">{t('cfd.12')}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:mt-[60px] xl:ml-[60px] m-[40px] xl:w-[398px]">
                                <p className="text-[#232323] open-sans font-[700]">{t('cfd.13')}</p>
                                <p className="mt-[30px] open-sans text-[#232323] text-[16px]">{t('cfd.14')}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:mt-[60px] xl:ml-[60px] m-[40px] xl:w-[398px]">
                                <p className="text-[#232323] open-sans font-[700]">{t('cfd.15')}</p>
                                <p className="mt-[30px] open-sans text-[#232323] text-[16px]">{t('cfd.16')}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:mt-[60px] xl:ml-[60px] m-[40px] xl:w-[398px]">
                                <p className="text-[#232323] open-sans font-[700]">{t('cfd.17')}</p>
                                <p className="mt-[30px] open-sans text-[#232323] text-[16px]">{t('cfd.18')}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:mt-[60px] xl:ml-[60px] m-[40px] xl:w-[398px]">
                                <p className="text-[#232323] open-sans font-[700]">{t('cfd.19')}</p>
                                <p className="mt-[30px] open-sans text-[#232323] text-[16px]">{t('cfd.20')}</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className='flex items-center gap-[22px] absolute right-[20px] top-[310px] justify-end'>
                        <div className="cursor-pointer button_swiper hover:bg-[#FA6640] transition-all" onClick={() => swiper.slidePrev()}>
                            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19 12H5" stroke="#232323" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 5L5 12L12 19" stroke="#232323" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <p className="text-[#424242] text-[20px] xl:text-[30px] roboto">{`0${currentIndex + 1}/05`}</p>
                        <div className="cursor-pointer button_swiper hover:bg-[#FA6640] transition-all" onClick={() => swiper.slideNext()}>
                            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19" stroke="#232323" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 5L19 12L12 19" stroke="#232323" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
               </div>
            </div>
        </div>
    );
}

export default WhyShould;